import React, { useState, useEffect, useCallback } from "react";
import { useWallet } from "../../context/WalletContext";
import { Contract } from "ethers";
import BoxNFT from "../ABI/boxNFT";
import axiosInstance from "../../axiosConfig";
import { Users, Award, DollarSign, Box, Gift } from "lucide-react";
import { useNotification } from "../Notifications/Notifications";
import Particles from "../../utils/particlesUtils";

const AffiliateDashboard = () => {
  const { wallet, provider } = useWallet();
  const [stats, setStats] = useState({ earnings: 0, referrals: 0 });
  const [affiliateLink, setAffiliateLink] = useState("");
  const [copied, setCopied] = useState(false);
  const { notify } = useNotification();
  const [boxes, setBoxes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opening, setOpening] = useState(false);

  const contractAddress = process.env.REACT_APP_BOX_NFT_CONTRACT_ADDRESS;

  const fetchUserBoxes = useCallback(async () => {
    if (!wallet || !provider || !contractAddress) {
      console.log("Missing requirements:", {
        wallet: !!wallet,
        provider: !!provider,
        contractAddress,
      });
      return;
    }

    try {
      // const contract = new Contract(contractAddress, BoxNFT, provider);

      // const balance = await contract.balanceOf(wallet.address);

      // const boxesData = [];
      // for (let i = 0; i < Number(balance); i++) {
      //   const tokenId = await contract.tokenOfOwnerByIndex(wallet.address, i);
      //   const box = await contract.getBox(tokenId);

      //   // On vérifie si la box est opened avant de l'ajouter
      //   if (!box.opened) {
      //     boxesData.push({
      //       tokenId: tokenId.toString(),
      //       type: ["BRONZE", "SILVER", "GOLD", "DIAMOND"][box.boxType],
      //       opened: box.opened,
      //       createdAt: new Date(Number(box.createdAt) * 1000),
      //     });
      //   }
      // }
      const boxesResponse = await axiosInstance.get(
        `/api/boxes/${wallet.address}`
      );

      setBoxes(boxesResponse.data);
    } catch (error) {
      console.error("Error fetching boxes:", error);
    }
  }, [contractAddress, provider, wallet]);

  const fetchStats = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        `/api/affiliate/stats?address=${wallet.address}`
      );
      setStats(res.data);
      if (res.data.affiliateCode) {
        setAffiliateLink(
          `${window.location.origin}?ref=${res.data.affiliateCode}`
        );
      }
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  }, [wallet.address]);

  const handleClaimBoxes = async () => {
    if (!wallet.address) return;
    setLoading(true);

    try {
      const res = await axiosInstance.post(`/api/boxes/claim`, {
        address: wallet.address,
      });

      if (res.data.success) {
        await fetchStats();
        await fetchUserBoxes();
        notify("Box claimed successfully!");
      }
    } catch (error) {
      console.error("Error claiming boxes:", error);
      if (error.response?.data?.error) {
        notify(error.response.data.error);
      } else {
        notify("Error claiming box");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOpenBox = async (tokenId) => {
    if (!wallet || !provider || !contractAddress) {
      notify("Please connect your wallet");
      return;
    }

    setOpening(true);

    try {
      const contract = new Contract(contractAddress, BoxNFT, wallet);

      // Récupérer les récompenses du backend
      const res = await axiosInstance.post(`/api/boxes/${tokenId}/open`, {
        address: wallet.address,
      });

      const tx = await contract.openBox(tokenId);
      await tx.wait();

      if (res.data.success) {
        await fetchStats();
        await fetchUserBoxes();
        notify(
          `Box opened! You received ${res.data.rewards.amount} ${res.data.rewards.type}`
        );
      }
    } catch (error) {
      console.error("Error opening box:", error);
      notify(error.reason || "Error opening box");
    } finally {
      setOpening(false);
    }
  };

  useEffect(() => {
    if (wallet?.address && provider && contractAddress) {
      fetchStats();
      fetchUserBoxes();
    }
  }, [wallet?.address, provider, contractAddress, fetchStats, fetchUserBoxes]);

  useEffect(() => {
    if (wallet.address) {
      fetchStats();
      fetchUserBoxes();
    }
  }, [wallet.address, fetchStats, fetchUserBoxes]);

  const handleCopy = () => {
    navigator.clipboard.writeText(affiliateLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const getTierInfo = () => {
    const referrals = stats.referrals;
    if (referrals >= 300)
      return { tier: "Diamond", commission: "5%", boxes: 1 };
    if (referrals >= 100) return { tier: "Gold", commission: "0%", boxes: 1 };
    if (referrals >= 50) return { tier: "Silver", commission: "0%", boxes: 1 };
    if (referrals >= 10) return { tier: "Bronze", commission: "0%", boxes: 1 };
    return { tier: "No", commission: "0%", boxes: 0 };
  };

  const tierInfo = getTierInfo();

  return (
    <div className="relative">
      {/* Particles en arrière-plan avec z-index négatif */}
      <div className="min-h-screen bg-[#1D1F24] text-white md:pl-[120px] md:pt-28 md:p-12 py-20 px-4">
      <div className="absolute">
        <Particles />
        <div className="rondBizarreOrangeQuiClignote" />
      </div>
        {/* Conteneur principal avec z-index positif */}
        <div className="relative">
          {/* Header Section */}
          <div className="mb-8">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
            Affiliate <span className="text-[#F4B000]">Dashboard</span>
          </h1>
          <p className="text-[#9da5b1] text-sm md:text-base">
            Track your referrals and earnings in real-time
          </p>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
          {/* Earnings Card */}
          <div className="bg-[#252832]/50 backdrop-blur-sm rounded-2xl p-6 hover:bg-[#2A2E38] transition-all duration-300">
            <div className="flex items-center mb-4 gap-3">
              <div className="bg-[#F4B000]/10 p-3 rounded-xl">
                <DollarSign className="w-6 h-6 text-[#F4B000]" />
              </div>
              <h3 className="text-lg font-semibold">Earnings</h3>
            </div>
            <p className="text-3xl font-bold text-[#F4B000]">
              {stats.earnings} REKT
            </p>
            <p className="text-sm text-[#9da5b1] mt-2">
              Total earnings from referrals
            </p>
          </div>

          {/* Referrals Card */}
          <div className="bg-[#252832]/50 backdrop-blur-sm rounded-2xl p-6 hover:bg-[#2A2E38] transition-all duration-300">
            <div className="flex items-center mb-4 gap-3">
              <div className="bg-[#F4B000]/10 p-3 rounded-xl">
                <Users className="w-6 h-6 text-[#F4B000]" />
              </div>
              <h3 className="text-lg font-semibold">Referrals</h3>
            </div>
            <p className="text-3xl font-bold text-[#F4B000]">
              {stats.referrals}
            </p>
            <p className="text-sm text-[#9da5b1] mt-2">Active referred users</p>
          </div>

          {/* Commission & Tier Card */}
          <div className="bg-[#252832]/50 backdrop-blur-sm rounded-2xl p-6 hover:bg-[#2A2E38] transition-all duration-300">
            <div className="flex items-center mb-4 gap-3">
              <div className="bg-[#F4B000]/10 p-3 rounded-xl">
                <Award className="w-6 h-6 text-[#F4B000]" />
              </div>
              <h3 className="text-lg font-semibold">{tierInfo.tier} Tier</h3>
            </div>
            <p className="text-3xl font-bold text-[#F4B000]">
              {tierInfo.commission}
            </p>
            <p className="text-sm text-[#9da5b1] mt-2">Commission rate</p>
          </div>

          {/* Monthly Boxes Card */}
          <div className="bg-[#252832]/50 backdrop-blur-sm rounded-2xl p-6 hover:bg-[#2A2E38] transition-all duration-300">
            <div className="flex items-center mb-4 gap-3">
              <div className="bg-[#F4B000]/10 p-3 rounded-xl">
                <Box className="w-6 h-6 text-[#F4B000]" />
              </div>
              <h3 className="text-lg font-semibold">Monthly Boxes</h3>
            </div>
            <p className="text-3xl font-bold text-[#F4B000]">
              {tierInfo.boxes}
            </p>
            <p className="text-sm text-[#9da5b1] mt-2 mb-4">
              {tierInfo.platformFees
                ? `+${tierInfo.platformFees} platform fees`
                : "Boxes per month"}
            </p>
            <button
              onClick={handleClaimBoxes}
              disabled={loading || !stats.canClaimBoxes}
              className={`w-full py-2 rounded-xl transition-all duration-300 flex items-center justify-center gap-2 mb-4
            ${
              stats.canClaimBoxes && !loading
                ? "bg-[#F4B000]/10 hover:bg-[#F4B000]/20 text-[#F4B000]"
                : "bg-[#F4B000]/5 text-[#F4B000]/50 cursor-not-allowed"
            }`}
            >
              {loading ? (
                <span>Processing...</span>
              ) : stats.canClaimBoxes ? (
                <>
                  <Gift className="w-4 h-4" />
                  <span>Claim Box</span>
                </>
              ) : (
                <span>Available in {stats.nextClaimIn || "30"} days</span>
              )}
            </button>
          </div>
        </div>

        {/* Liste des boxes NFT */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 bg-[#252832]/50 backdrop-blur-sm rounded-2xl p-6 md:p-8 mb-4">
          {boxes.map(
            (box) =>
              !box.opened && (
                <div key={box.tokenId} className="bg-[#1D1F24] p-4 rounded-xl">
                  <div className="flex justify-between items-center mb-2">
                    <h4 className="font-semibold">{box.type} Box</h4>
                    <span className="text-sm text-[#9da5b1]">
                      #{box.tokenId}
                    </span>
                  </div>
                  <button
                    onClick={() => handleOpenBox(box.tokenId)}
                    disabled={loading}
                    className="w-full py-2 mt-2 rounded-xl bg-[#F4B000]/10 hover:bg-[#F4B000]/20 text-[#F4B000] flex items-center justify-center gap-2"
                  >
                    <Gift className="w-4 h-4" />
                    <span>{opening ? "Opening..." : "Open Box"}</span>
                  </button>
                </div>
              )
          )}
        </div>

        {/* Affiliate Link Section */}
        <div className="bg-[#252832]/50 backdrop-blur-sm rounded-2xl p-6 md:p-8">
          <h3 className="text-xl font-bold mb-6">Your Referral Link</h3>
          <div className="flex flex-col md:flex-row gap-4">
            <input
              type="text"
              value={affiliateLink}
              readOnly
              className="flex-1 bg-[#1D1F24] p-4 rounded-xl border border-[#F4B000]/20 focus:border-[#F4B000] outline-none text-sm md:text-base"
            />
            <button
              onClick={handleCopy}
              className={`px-6 py-4 rounded-xl font-semibold transition-all duration-300 ${
                copied
                  ? "bg-green-500/20 text-green-500"
                  : "bg-[#F4B000] text-black hover:bg-[#F4B000]/90"
              }`}
            >
              {copied ? "Copied!" : "Copy Link"}
            </button>
          </div>
          <p className="text-[#9da5b1] text-sm mt-4">
            Share this link with your friends and earn rewards when they join
            and participate in tournaments or bets
          </p>
        </div>
      </div>
      </div>
    </div>
  );
};

export default AffiliateDashboard;
