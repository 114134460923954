import React from "react";
import { Users, Crown, ChevronRight, Coins, Box, Percent } from "lucide-react";
import axiosInstance from "../../axiosConfig";
import { useWallet } from "../../context/WalletContext";
import Particles from "../../utils/particlesUtils";

const AffiliateRewardsPage = () => {
  const { wallet } = useWallet();
  const tiers = [
    {
      name: "Bronze",
      icon: <Crown className="w-8 h-8 text-[#CD7F32]" />,
      referrals: "10-49",
      rewards: [
        "1 Bronze Box per month",
        "Access to basic affiliate dashboard",
      ],
      color: "from-[#CD7F32]",
    },
    {
      name: "Silver",
      icon: <Crown className="w-8 h-8 text-[#C0C0C0]" />,
      referrals: "50-99",
      rewards: [
        "1 Silver Boxes per month",
        "Custom referral links",
        "Priority support",
      ],
      color: "from-[#C0C0C0]",
    },
    {
      name: "Gold",
      icon: <Crown className="w-8 h-8 text-[#F4B000]" />,
      referrals: "100-299",
      rewards: [
        "1 Gold Boxes per month",
        "Exclusive promotional materials",
        "Early access to new features",
      ],
      color: "from-[#F4B000]",
    },
    {
      name: "Diamond",
      icon: <Crown className="w-8 h-8 text-[#B9F2FF]" />,
      referrals: "300+",
      rewards: [
        "5% commission on referral earnings",
        "1 Diamond Boxes per month",
        "VIP support channel",
        "Custom commission structures",
        "Invitation to annual partner summit",
      ],
      color: "from-[#B9F2FF]",
    },
  ];

  const features = [
    {
      icon: <Box className="w-6 h-6" />,
      title: "Monthly Boxes",
      description: "Earn exclusive boxes based on your tier level",
    },
    {
      icon: <Percent className="w-6 h-6" />,
      title: "Platform Fee Share",
      description: "Diamond tier members earn from overall platform fees",
    },
    {
      icon: <Users className="w-6 h-6" />,
      title: "Unlimited Referrals",
      description: "No cap on how many users you can refer",
    },
    {
      icon: <Coins className="w-6 h-6" />,
      title: "Lifetime Commissions",
      description: "Earn from your referrals' activities forever",
    },
  ];

  const handleClick = async () => {
    try {
      if (!wallet.address) {
        alert("Please connect your wallet first");
        return;
      }

      const response = await axiosInstance.post(
        "/api/affiliate/become-affiliate",
        {
          address: wallet.address,
        }
      );

      if (response.data.success) {
        window.location.reload();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="relative">
      {/* Particles en arrière-plan avec z-index négatif */}
      <div className="min-h-screen bg-[#1D1F24] text-white md:pl-[120px] md:pt-28 md:p-12 py-20 px-4">
        <div className="absolute">
          <Particles />
          <div className="rondBizarreOrangeQuiClignote" />
        </div>
                {/* Conteneur principal avec z-index positif */}
                <div className="relative">
          {/* Hero Section */}
          <div className="max-w-6xl mx-auto text-center mb-16">
            <h1 className="text-4xl lg:text-6xl font-bold mb-6">
              Affiliate <span className="text-[#F4B000]">Rewards</span> Program
            </h1>
            <p className="text-xl text-[#9da5b1] max-w-2xl mx-auto">
              Earn rewards, exclusive boxes, and platform fee shares by
              referring new players to REKT. The more successful referrals you
              bring, the higher your rewards tier becomes.
            </p>
          </div>

          {/* Features Grid */}
          <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-[#252832] p-6 rounded-xl hover:bg-[#2A2E38] transition-colors"
              >
                <div className="text-[#F4B000] mb-4">{feature.icon}</div>
                <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                <p className="text-[#9da5b1]">{feature.description}</p>
              </div>
            ))}
          </div>

          {/* Tiers Section */}
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl font-bold text-center mb-12">
              Reward <span className="text-[#F4B000]">Tiers</span>
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {tiers.map((tier, index) => (
                <div key={index} className="relative">
                  <div
                    className="absolute inset-0 bg-gradient-to-b opacity-10 rounded-xl"
                    style={{
                      background: `linear-gradient(to bottom, ${tier.color}, transparent)`,
                    }}
                  />
                  <div className="bg-[#252832] p-6 rounded-xl relative">
                    <div className="flex items-center justify-between mb-4">
                      {tier.icon}
                      <span className="text-[#F4B000] font-semibold">
                        {tier.referrals} referrals
                      </span>
                    </div>
                    <h3 className="text-xl font-bold mb-4">{tier.name}</h3>
                    <ul className="space-y-3">
                      {tier.rewards.map((reward, rewardIndex) => (
                        <li
                          key={rewardIndex}
                          className="flex items-start text-[#9da5b1]"
                        >
                          <ChevronRight className="w-4 h-4 text-[#F4B000] mt-1 mr-2 flex-shrink-0" />
                          <span>{reward}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* CTA Section */}
          <div className="max-w-4xl mx-auto mt-16 text-center p-8 bg-[#252832] rounded-xl border border-[#F4B000]/20">
            <h3 className="text-2xl font-bold mb-4">Start Earning Today</h3>
            <p className="text-[#9da5b1] mb-6">
              Join our affiliate program to earn commissions, exclusive boxes,
              and platform fee shares
            </p>
            <button
              onClick={handleClick}
              className="bg-gradient-to-r from-[#F4B000] to-[#FF6B6B] px-6 py-3 rounded"
            >
              Become an Affiliate
            </button>
          </div>
        </div>
      </div>
      </div>
  );
};

export default AffiliateRewardsPage;
